import React from 'react'
import Layout from './Layout'
import Seo from './Seo'

export const mdxPageLayout = (
	title: string,
	description: string,
	image?: string,
): React.FC<void> => {
	return (props) => (
		<Layout>
			<Seo title={title} description={description} image={{ src: image }} />
			{props.children}
		</Layout>
	)
}
