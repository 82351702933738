import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import { mdxPageLayout } from "../components/MdxPageLayout";
import { Socials } from "../components/Socials";
import { Box, Typography } from "@mui/material";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = mdxPageLayout("About Walid Lezzar", "Walid Lezzar bio page", "/assets/images/profile-pic.png");
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1>{`About me`}</h1>
    <Box display="grid" gridTemplateColumns={{
      xs: "1fr",
      sm: "1fr",
      sm: "1fr 2fr"
    }} gap="2rem" mt="3rem" alignItems="center" mdxType="Box">
  <Box mdxType="Box">
    <img src="/assets/images/profile-pic.png" loading="lazy" decoding="async" style={{
          borderRadius: "50%",
          maxWidth: "100%",
          height: "auto"
        }} />
  </Box>
      <Box mdxType="Box">
        <p>{`I’m Walid, a software engineer at Amazon. I live in Canada.`}</p>
        <p>{`Reach out to me via Twitter (or any other social media below) if you have a comment, feedback, or simply want to connect! 😉`}</p>
        <Socials size="large" sx={{
          mx: "auto",
          my: "2rem"
        }} mdxType="Socials" />
      </Box>
    </Box>
    <h2>{`💻 Experience`}</h2>
    <p>{`I started my career as a Data Engineer at `}<a parentName="p" {...{
        "href": "https://www.afnic.fr/en/"
      }}>{`AFNIC`}</a>{` followed by `}<a parentName="p" {...{
        "href": "https://www.leboncoin.fr/"
      }}>{`Leboncoin`}</a>{`, both in France. During that time, I worked on building data architectures on AWS, and I had the opportunity to work with a large spectrum of tools and technologies. Spark, Kafka, Airflow, and Redshift were the main ones I regularly used.`}</p>
    <p>{`I then switched to become a Backend Engineer at `}<a parentName="p" {...{
        "href": "https://www.adevinta.com/"
      }}>{`Adevinta`}</a>{`, where I spent a good chunk of my years building services on top of AWS and Kubernetes. I had the chance to perform as a Tech Lead during my last year before sailing off for new adventures! 🌎`}</p>
    <p>{`I’m now working at Amazon in Toronto since 2021. A big move, both personally and professionally!`}</p>
    <h2>{`🦄 Open Source Projects`}</h2>
    <p>{`I have a few projects in my Github. Feel free to take a look or open a discussion on Github:`}</p>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://github.com/adevinta/zoe"
        }}>{`Zoe The kafka CLI for humans`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://github.com/wlezzar/jtab"
        }}>{`Jtab: Print json data as a table from the command line`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://github.com/wlezzar/doks"
        }}>{`Doks: Search for your distributed documentation in one place`}</a></li>
    </ul>
    <p>{`As you can see, my languages of choice are mainly Kotlin & Rust! 😄`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      